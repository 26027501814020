import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/layout.js";
import { Box, Divider, Button } from "theme-ui";
import AniLink from "gatsby-plugin-transition-link/AniLink";
import SEO from '../components/seo';
import ConsultingHero from "../components/ConsultingHero";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <SEO title="IT Consulting Services" description="" mdxType="SEO" />
    <ConsultingHero mdxType="ConsultingHero" />
    <Box sx={{
      pt: [4, 5, 5],
      pb: [4, 5, 5],
      pl: [3, 5, "80px"],
      pr: [3, 5, "80px"]
    }} mdxType="Box">
    </Box>
    <Box sx={{
      pt: [4, 5, 5],
      pb: 0,
      pl: [3, 5, "80px"],
      pr: [3, 5, "80px"]
    }} mdxType="Box">
    <Divider mdxType="Divider" />
    <h2 style={{
        textAlign: "center",
        marginTop: "40px"
      }}>Like what you see and ready to chat?</h2>
    <Button variant="cta" sx={{
        marginTop: [4, 5, 5],
        marginBottom: [3, 3, 3],
        marginLeft: "auto",
        marginRight: "auto",
        paddingLeft: "0px",
        paddingRight: "0px",
        display: "block"
      }} mdxType="Button">
        <AniLink fade title="Get in touch with Thinkanew Media" duration={0.35} to={`/contact-us/`} style={{
          textDecoration: "inherit",
          color: "inherit",
          padding: "14px 20px"
        }} mdxType="AniLink">
        Get in touch
        </AniLink>
    </Button>  
    </Box>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      